import {shortOperatorForTemplates,filterItemToString} from "o365.modules.filterUtils.ts";

export default class FilterTemplateItem {
    private _nonLookups:Array<string> = ["{{textbox}}","{{checkbox}}","{{radio}}","{{date_picker}}","{{date_time_picker}}","{{time_picker}}","{{multi_select}}"]
    valueType:string;
    operator:string;
    editorType:string = "";
    column:string;
    _value:any;
    
    _isRange:boolean = false;
    rangeValue0:any;
    rangeValue1:any;
    _caption:string;
    type:string="expression";
    inputEditor:string = "";
    inputEditorParsed:string = "";
    private _debounce:any|null = null;
    private _changeEvt:Function|null = null;
    _displayValue:any;
    
    set changeEvt(pEvt:Function){
        this._changeEvt = pEvt;
    }

    get displayValue(){
        if(!this._displayValue) return this.value;
        return this._displayValue;
    }

    set displayValue(pValue){
        this._displayValue = pValue;
    }

    /*set columns(pColumns:Array<any>){

        this._columns = pColumns;
        this._updateTypefromColumns();
    }*/

    get caption(){
        return this._caption;
    }

    set caption(pValue:string){
        this._caption = pValue;
    }

    get shortOperator(){
        return shortOperatorForTemplates(this.operator);
    }

    get itemAsString(){
       
        return filterItemToString(this.item);
    }

    get item(){
    
        return {
            column:this.column,
            caption:this.caption??this.column,
            operator:this.operator,
            valueType:this.valueType,
            value:this.value,
            displayValue:this.displayValue,
            inputEditor:this.inputEditor,
            type:"expression"
        }
    }


    get itemForFilter(){
        return {
           // column:this.column,
            column:this.caption??this.column,
            caption:this.caption??this.column,
            operator:this.operator,
            valueType:this.valueType,
            value:this.inputEditor == "Input Editor"||this.inputEditor?.startsWith("{{")?this.inputEditor:this.displayValue,
            type:"expression"
        }
    }
  

    get isRange(){
        return this._isRange;
        
    }

    get value(){
        if(this.isRange){
            if(!this.rangeValue0) return this.rangeValue0;
            if(!this.rangeValue1) return this.rangeValue1;
            return [this.rangeValue0,this.rangeValue1];
        }
       // if(!this._value && this.inputEditor) return this.inputEditor;
       if(this._value == undefined) return undefined;
       if(this.valueType == "string" && !this._value) return undefined;
        return this._value;
    }

    set value(pValue:any){
        if(this.inputEditor == "Fixed Value" && this._displayValue){
            this._displayValue = null;
        }
        if(pValue && pValue.hasOwnProperty("name")){
            this._value = "["+pValue.name+"]";
            return;
        }
        this._value = pValue;
        if(this._changeEvt) {
            if(this._debounce) clearTimeout(this._debounce)
            const vThat = this;
            this._debounce = window.setTimeout(()=>{
                if(vThat._changeEvt) vThat._changeEvt.call(this,{
                    column:this.column,
                    valueType:this.valueType,
                    value:this.value
                });
            },50)
            

        }
    }   
    
    get isLookup(){
        return this.inputEditor && this._nonLookups.indexOf(this.inputEditor) == -1
    }
    get isColumn(){
        return this.value && typeof this.value === 'string' && this.value.startsWith("[") && this.value.endsWith("]");
    }

    get editor(){
        let vReturn = "";
        if(!this.inputEditor){
            return null;
        }
        if(this.isRange){
            if(this.valueType == "datetime") return "datetime";
        }

       if(this.isLookup) return this.inputEditor;

        switch(this.inputEditor){
            case "{{date_picker}} ": 
            
                vReturn = "datetime";
                break;
            case "{{textbox}}": 
                vReturn = "textbox";
                break;
            case "{{checkbox}}": 
                vReturn = "bit";
                break;
        }

        if(!vReturn){
            return this.valueType;
        }
        return vReturn;
    }

    get name(){
        return this.column;
    }
    constructor(item:any){


        //this._item = item;
        this.column = item.column;
        this.operator = item.operator;
        this._caption = item.caption??item.column;
        
        this.valueType = item.valueType;
               
        if(item.inputEditor){
            if(item.inputEditor == "Input Editor" && !item.value.startsWith("[")){
                this.inputEditor = "{{"+item.value.toLowerCase()+"}}";
                this.inputEditorParsed = item.value.toLowerCase();
                //this.value = this.inputEditor;
            }else if(item.inputEditor == "Field" || item.inputEditor == "Fixed Value"){
                this.value = item.value;
            }else{
                this.inputEditor = item.inputEditor;
                if(item.inputEditor.startsWith("{{")){
                    this.inputEditorParsed = item.inputEditor.toLowerCase().replace("{{","").replace("}}","");
                }
                this.value = item.value;
            }

        }else{

        
            if(item.value && typeof item.value == 'string' && item.value.startsWith("{{")){
                this.inputEditorParsed = item.value.toLowerCase().replace("{{","").replace("}}","");
                this.inputEditor = item.value.toLowerCase();
                if(this.inputEditor == "{{date_picker}}")
                this.valueType = "datetime";
            }else if(item.value && typeof item.value == "object" && item.value.hasOwnProperty("first")){
                this.inputEditor =  item.value;

            }else if(item.value && item.value.constructor == Array && item.value.length == 1){
                this.inputEditor =  item.value[0];
                this.value =  item.value[0];
                if(this.inputEditor?.startsWith("{{")){
                    this.inputEditorParsed = this.inputEditor.toLowerCase().replace("{{","").replace("}}","");
                }

                console.log(this);
            }else{
                this.value = item.value; 
            }
        }
        this._isRange = (this.inputEditor != undefined && typeof this.inputEditor !== "string");

        if(item.displayValue){
            this.displayValue = item.displayValue;
        }
      
    }

}